<template>
  <div style="background-color: #f6f6f6" v-if="detail.name">
    <van-nav-bar
      :title="detail.name ? detail.name : '课程详情'"
      :left-arrow="!share"
      @click-left="$goBack()"
      fixed
      placeholder
      @click-right="shareBoxShow = true"
    >
      <template #right>
        <img
          v-if="!share"
          src="../../assets/img/takeOutFood/share.png"
          alt=""
          style="width: 24px; height: 24px"
        />
      </template>
    </van-nav-bar>

    <div>
      <img :src="detail.bigImg" alt="" style="width: 100%" />
    </div>

    <div class="title-box">
      <div>
        <div class="title">{{ detail.name }}</div>
        <div class="price">
          <span>￥</span>
          <span>{{ detail.price }}</span>
        </div>
      </div>
      <div class="num">
        <span>{{ detail.peopleNum || 0 }}</span>
        <span>人报名</span>
      </div>
    </div>

    <div>
      <van-tabs
        v-model="active"
        line-width="10"
        color="#ff5200"
        title-active-color="#ff5200"
        title-inactive-color="#999"
        swipeable
      >
        <van-tab title="课程介绍">
          <div class="desc" v-html="detail.courseIntroduction"></div>
        </van-tab>

        <van-tab title="名师简介">
          <div class="teacher">
            <div
              class="teacher-item"
              v-for="(item, index) in detail.courseTeachers"
              :key="index"
            >
              <div class="item-left">
                <img :src="item.img" alt="" />
              </div>
              <div class="item-right">
                <div style="margin-bottom: 5px">
                  <span class="name">{{ item.name }}</span>
                  &nbsp;
                  <span class="explain">(仅供参考，具体以实际授课为准)</span>
                </div>
                <div class="explain">{{ item.introduction }}</div>
              </div>
            </div>
          </div>
        </van-tab>

        <van-tab title="课程目录">
          <div class="catalog">
            <div
              class="catalog-item"
              v-for="(item, index) in detail.courseCatalogues"
              :key="index"
            >
              <div class="first">{{ item.content }}</div>
              <div class="second">
                <div
                  class="second-item"
                  v-for="(item2, index2) in item.catalogueChilds"
                  :key="index2"
                >
                  {{ item2.content }}
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <div style="height: 56px; background-color: #fff"></div>
    <div class="bottom adapt" >
      <van-button
        v-if="!detail.status"
        type="primary"
        size="small"
        round
        color="linear-gradient(to right, #FF7200, #FF3C00)"
        @click="btnClick"
        >立即报名</van-button
      >
      <van-button v-else type="primary" size="small" round disabled color="#000"
        >已报名</van-button
      >
    </div>

   

    <van-popup
      v-model="show"
      position="bottom"
      round
      @close="$refs.vanForm.resetValidation()"
    >
      <div class="adapt">
        <div class="popup-title">课程报名</div>
        <van-form ref="vanForm" @submit="submit">
          <div class="form-item">
            <div class="item-label">报名课程</div>
            <div class="item-text">{{ detail.name }}</div>
          </div>

          <div class="form-item">
            <div class="item-label">姓名</div>
            <van-field
              v-model="personName"
              name="姓名"
              placeholder="请输入姓名"
              autocomplete="off"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
          </div>

          <div class="form-item">
            <div class="item-label">联系电话</div>
            <van-field
              v-model="phone"
              name="联系电话"
              placeholder="请输入联系电话"
              autocomplete="off"
              :rules="[
                { required: true, message: '请填写电话' },
                { pattern, message: '请填写正确的电话号码' },
              ]"
            />
          </div>
        </van-form>
        <div style="height: 56px"></div>
        <div class="bottom adapt">
          <van-button
            type="primary"
            size="small"
            round
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="btnClick"
            >立即报名</van-button
          >
        </div>
      </div>
    </van-popup>

    <share-box v-model="shareBoxShow" :data="shareData"></share-box>
  </div>
</template>

<script>
import { getLesson, applyLesson } from "@/api/school";
import shareBox from "@/components/share-box";
export default {
  name: "schoolDetail",
  components: { shareBox },
  data() {
    return {
      id: "1461616851522113538",
      share: null,

      detail: {
        bigImg: null,
        name: null,
        price: null,
        peopleNum: null,
        courseIntroduction: null,
        courseTeachers: [],
        courseCatalogues: [],
        status: null,
      },

      active: 0,
      show: false,

      personName: "",
      phone: "",
      pattern: /^1[0-9]{10}$/,

      shareBoxShow: false,
      shareData: {},
      shareUserId: null,
    };
  },
  created() {
    if (navigator.userAgent.indexOf("万民") == -1) {
      this.openApp();
      return;
    }
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
    this.id = this.$route.query.id;
    if (this.$route.query.shareUserId) {
      // this.share = this.$route.query.share;
      this.shareUserId = this.$route.query.shareUserId;
    }
    this.getLesson();
  },
  methods: {
    // 获取详情
    getLesson() {
      getLesson(this.id).then((res) => {
        console.log(res);
        this.detail = res.data.data;
        let url = window.location.href;
        this.shareData = {
          title: "万民商学院",
          picUrl: this.detail.bigImg,
          content: this.detail.name,
          openUrl:
            url.split("session")[0] +
            "shareUserId=" +
            this.detail.userId +
            "&session" +
            url.split("session")[1],
        };
      });
    },
    // 立即报名按钮
    btnClick() {
      if( !navigator.userAgent.includes('万民')){
            let urls = location.href.split("#/")[1].split("&share=1");
            let url = urls[0] + urls[1];
            this.openApp(url);
            return
      }



       
        if( !navigator.userAgent.includes('万民')){ 
              let urls = location.href.split("#/")[1].split("&share=1");
              let url = urls[0] + urls[1];
              this.openApp(url);
              
              return
        } 

        if (this.show) {
          this.$refs.vanForm.submit();
        } else {
          this.show = true;
        }



     

    },
    // 表单提交
    submit() {
      let obj = {
        courseId: this.id,
        phone: this.phone,
        personName: this.personName,
        paymentPrice: this.detail.price,
      };
      if (this.shareUserId) {
        obj.shareUserId = this.shareUserId;
      }
      applyLesson(obj).then((res) => {
        console.log(res);
        this.getLesson();
        this.show = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  .title {
    color: #333333;
    margin-bottom: 5px;
  }
  .price {
    color: #ff5200;
  }
  .num {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
/deep/ .van-tab__text {
  font-size: 16px;
}
.desc,
.teacher,
.catalog {
  background-color: #fff;
}
.desc {
  padding: 10px 0;
}
.teacher {
  .teacher-item {
    display: flex;
    padding: 16px;
    .item-left {
      flex-shrink: 0;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
    .item-right {
      margin-left: 14px;
      .name {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .explain {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
.catalog {
  padding: 16px;
  .catalog-item {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .first {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }
    .second {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      .second-item {
        margin-bottom: 8px;
      }
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 56px;

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  .van-button {
    height: 36px;
    margin-top: 10px;
    margin-left: 10px;
    width: calc(100% - 20px);
  }
}
.adapt {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.popup-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  height: 40px;
}
.form-item {
  border-bottom: 1px solid #eee;
  .item-label {
    padding: 10px 16px 0;
    font-size: 14px;
    color: #999;
  }
  .item-text {
    color: #333;
    padding: 10px 16px;
    font-size: 14px;
  }
}
/deep/.van-field__error-message {
  display: none;
}
</style>