import https from '@/router/https'

// 商学院课程详情
export function getLesson(id) {
	return https.fetchGet('/createrapp/courseinfo/' + id)
}

// 商学院课程报名
export function applyLesson(param) {
	return https.fetchPost('/createrapp/courseorder', param)
}
